import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the context type
interface ThemeContextType {
    isLightMode: boolean;
    setIsLightMode: (isLightMode: boolean) => void;
}

// Create the context with default values
const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

// Provider component to wrap the app
export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const [isLightMode, setIsLightMode] = useState<boolean>(false);

    return (
        <ThemeContext.Provider value={{ isLightMode, setIsLightMode }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the theme context
export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
