import mohamedImg from "../assets/team/Mohammed Zeanalabdeen _ Project Manager _ New york, USA.png";
import hozifaImg from "../assets/team/Hozifa Zane _ CTO _ Chicago, USA.png";
import ginwanImg from "../assets/team/Ginwan Gasim _ Software Engineer _ Abudhabi, UAE.png";
import lamaImg from "../assets/team/Lama Yousef _ Software Engineer _ Dubai, UAE.png";
import danialImg from "../assets/team/Danial G _ Software Engineer _ Texas, USA.png";
import abdualwahabImg from "../assets/team/Abdualwahab _ System Administrator _ Dubai, UAE.png";
import hashimImg from "../assets/team/Hashim Salih _ Software Engineer _ Dubai, UAE.png";
import mohammedImg from "../assets/team/Mohammed Abdalla _ Software Engineer _ Dubai, UAE.png";

export const teams = [
  {
    img: mohamedImg,
    name: "Mohamed Z",
    role: "Project Manager",
    location: "New York, USA",
  },
  {
    img: hozifaImg,
    name: "Hozifa Z",
    role: "CTO",
    location: "Chicago, USA",
  },
  {
    img: ginwanImg,
    name: "Ginwan E",
    role: "Software Eng",
    location: "Abu Dhabi, UAE",
  },
  {
    img: lamaImg,
    name: "Lama Y",
    role: "Software Eng",
    location: "Dubai, UAE",
  },
  {
    img: danialImg,
    name: "Danial G",
    role: "Software Eng",
    location: "Texas, USA",
  },
  {
    img: abdualwahabImg,
    name: "Abdualwahab",
    role: "System Admin",
    location: "Dubai, UAE",
  },
  {
    img: hashimImg,
    name: "Hashim S",
    role: "Software Eng",
    location: "Abu Dhabi, UAE",
  },
  {
    img: mohammedImg,
    name: "Mohmed A",
    role: "Software Eng",
    location: "Dubai, UAE",
  },
];
