import { BrowserRouter } from "react-router-dom";
import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import Lottie from "react-lottie";
import Illustration from "../../assets/Animation1719808748391.json";
import Hello from "../../assets/Hello.gif";
import { useStatisticsContext } from "../../context/statisticsContext";

export function Hero() {
  const isSmallScreen = window.innerWidth <= 768;

  const size = isSmallScreen ? 200 : 400;

  const { setActionType } = useStatisticsContext();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Illustration,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleConsultation = () => {
    setActionType("free-consultation-hero");
  };

  return (
    <>
      <Container id="home">
        <div className="hero-text">
          <ScrollAnimation animateIn="fadeInUp">
            <p className="greeting">
              Hello <img src={Hello} alt="Hello" width="20px" />, This is
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
            <h1>Zane Technology</h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.4 * 1000}>
            <h3>Transforming Ideas into Reality</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
            <p className="small-resume" style={{ textAlign: "justify" }}>
              At Zane Tech Solutions, we specialize in providing cutting-edge
              technology services to small businesses and companies. Our goal is
              to help you achieve your business objectives by delivering
              custom-tailored solutions that meet your unique needs.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={0.7 * 1000}>
            <div className="small-resume">
              <h3>Our Process:</h3>
              <ol>
                <ScrollAnimation animateIn="fadeInUp" delay={0.9 * 1000}>
                  <li>
                    1- Consultation: We meet with you to discuss your needs.
                  </li>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={1.1 * 1000}>
                  <li>
                    2- Planning: We create a detailed plan to address your
                    requirements.
                  </li>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={1.1 * 1000}>
                  <li>
                    3- Development: Our team develops and implements the
                    solutions.
                  </li>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={1.1 * 1000}>
                  <li>
                    4- Testing: We rigorously test the solutions to ensure they
                    meet your expectations.
                  </li>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp" delay={1.1 * 1000}>
                  <li>
                    5- Deployment: We deploy the solutions and provide ongoing
                    support.
                  </li>
                </ScrollAnimation>
              </ol>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={1.3 * 1000}>
            <p>Ready to take your business to the next level?</p>
          </ScrollAnimation>
          <br />
          <ScrollAnimation animateIn="fadeInUp" delay={1 * 1000}>
            <BrowserRouter>
              <a
                href="#booking"
                className="button"
                onClick={handleConsultation}
              >
                Book your free Consultation
              </a>
            </BrowserRouter>
          </ScrollAnimation>
        </div>
        <div className="hero-image">
          <ScrollAnimation animateIn="fadeInRight" delay={1 * 1000}>
            <Lottie options={defaultOptions} height={size} width={size} />
          </ScrollAnimation>
        </div>
      </Container>
      {/* <ScrollAnimation animateIn="fadeInUp" delay={0.6 * 1000}>
        <h1>
          At Zane Tech Solutions, we specialize in providing cutting-edge
          technology services to small businesses and companies. Our goal is to
          help you achieve your business objectives by delivering
          custom-tailored solutions that meet your unique needs.
        </h1>
      </ScrollAnimation> */}
    </>
  );
}
