import { isMobile } from 'react-device-detect';

interface Props {
	isLightMode: boolean
}

const HeaderLogo = ({ isLightMode }: Props) => {
	return (
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 100 30.42" className='header-logo-style' xmlSpace="preserve" height={50} width={isMobile ? 100 : 180}>
			<style type="text/css">
				{`.st0{fill:${isLightMode ? "#0D315F" : "#FCF3F0"};}
                .st1{fill:#E35A18;}`}
			</style>
			<g>
				<g>
					<path className="st0" d="M11.98,24.68H0.07L9.75,0.56h11.9L11.98,24.68z M3.45,11.99L1,12.18V0.56h6.9L3.45,11.99z M21.19,24.68h-7.27
			l3.61-9.45l3.66-0.14V24.68z"/>
					<path className="st0" d="M29.71,8.94l4.95,15.28c-0.79,0.42-2.36,0.69-3.98,0.69c-4.68,0-7.27-2.92-7.27-8.06
			C23.41,12.73,26.01,9.63,29.71,8.94z M40.78,0.56l7.59,24.13H36.84L29.06,0.56H40.78z"/>
					<path className="st0" d="M60.74,0.56v24.13H50.18V0.56H60.74z M64.81,0.56c4.77,0,8.75,2.55,8.75,9.68v14.45H62.64V0.56H64.81z" />
					<path className="st0" d="M87,15.93h12.04c0.37,5.46-3.7,9.35-10.6,9.35c-8.57,0-12.13-3.61-12.13-12.41C76.3,3.38,80.74,0,87,0V15.93z
			"/>
					<path className="st1" d="M88.85,0c7.36,0,11.62,4.4,11.02,14.26H88.85V0z" />
				</g>
				<g>
					<path className="st1" d="M18.66,26.85v0.27h-0.69v2.3h-0.33v-2.3h-0.69v-0.27H18.66z" />
					<path className="st1" d="M20.87,28.54h-1.59c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C20.88,28.4,20.88,28.47,20.87,28.54z M20.45,27.94c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C20.54,28.14,20.51,28.03,20.45,27.94z"/>
					<path className="st1" d="M21.31,27.86c0.08-0.16,0.2-0.28,0.34-0.36c0.15-0.09,0.31-0.13,0.5-0.13c0.24,0,0.45,0.06,0.6,0.18
			c0.16,0.12,0.26,0.29,0.31,0.5h-0.36c-0.03-0.12-0.1-0.22-0.2-0.29c-0.1-0.07-0.22-0.11-0.36-0.11c-0.19,0-0.34,0.07-0.46,0.2
			c-0.12,0.13-0.17,0.32-0.17,0.56c0,0.24,0.06,0.43,0.17,0.56c0.12,0.13,0.27,0.2,0.46,0.2c0.14,0,0.27-0.03,0.36-0.1
			c0.1-0.07,0.16-0.17,0.2-0.3h0.36c-0.05,0.21-0.16,0.37-0.32,0.5c-0.16,0.12-0.36,0.19-0.6,0.19c-0.19,0-0.36-0.04-0.5-0.13
			c-0.15-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C21.19,28.2,21.23,28.02,21.31,27.86z"/>
					<path className="st1" d="M24.91,27.46c0.12,0.07,0.22,0.16,0.28,0.29c0.07,0.13,0.1,0.29,0.1,0.48v1.19h-0.33v-1.14
			c0-0.2-0.05-0.36-0.15-0.46c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33
			V26.7h0.33v1c0.07-0.1,0.16-0.18,0.27-0.24s0.24-0.08,0.39-0.08C24.65,27.37,24.79,27.4,24.91,27.46z"/>
					<path className="st1" d="M27.39,27.59c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33V27.4h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C27.04,27.37,27.24,27.44,27.39,27.59z"/>
					<path className="st1" d="M28.51,29.33c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13s0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C28.83,29.46,28.67,29.42,28.51,29.33z M29.35,29.08c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			c0-0.16-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08C29.14,29.16,29.25,29.14,29.35,29.08z"/>
					<path className="st1" d="M30.8,26.7v2.73h-0.33V26.7H30.8z" />
					<path className="st1" d="M31.73,29.33c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C32.05,29.46,31.88,29.42,31.73,29.33z M32.57,29.08c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			c0-0.16-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08C32.35,29.16,32.46,29.14,32.57,29.08z"/>
					<path className="st1" d="M34.96,27.49c0.13,0.08,0.23,0.17,0.29,0.29V27.4h0.33v2.07c0,0.18-0.04,0.35-0.12,0.49
			c-0.08,0.14-0.19,0.26-0.33,0.34c-0.14,0.08-0.31,0.12-0.5,0.12c-0.26,0-0.48-0.06-0.65-0.19c-0.17-0.13-0.28-0.3-0.31-0.51h0.33
			c0.04,0.12,0.11,0.22,0.22,0.3c0.11,0.07,0.25,0.11,0.41,0.11c0.18,0,0.33-0.06,0.44-0.17c0.11-0.12,0.17-0.28,0.17-0.49v-0.42
			c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37
			c-0.08-0.16-0.12-0.34-0.12-0.55c0-0.21,0.04-0.39,0.12-0.54c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13
			C34.68,27.37,34.83,27.41,34.96,27.49z M35.15,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09
			c-0.12,0-0.24,0.03-0.34,0.09c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4
			c0.06,0.11,0.14,0.2,0.24,0.26c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26
			c0.06-0.11,0.09-0.25,0.09-0.4C35.24,28.26,35.21,28.13,35.15,28.01z"/>
					<path className="st1" d="M37.85,27.4l-1.2,2.97h-0.34l0.39-0.97l-0.8-2h0.37l0.62,1.64l0.62-1.64H37.85z" />
					<path className="st1" d="M39.14,27.86c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13c0.17,0,0.32,0.04,0.45,0.11
			c0.13,0.08,0.22,0.17,0.29,0.29V27.4h0.33v2.02H40.7v-0.38c-0.07,0.12-0.16,0.22-0.29,0.29c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C39.02,28.2,39.06,28.02,39.14,27.86
			z M40.61,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C40.7,28.26,40.67,28.13,40.61,28.01z"/>
					<path className="st1" d="M43.15,27.59c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33V27.4h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C42.8,27.37,43,27.44,43.15,27.59z"/>
					<path className="st1" d="M43.91,27.86c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.31-0.13,0.48-0.13c0.15,0,0.3,0.04,0.43,0.11
			c0.13,0.07,0.23,0.17,0.3,0.29V26.7h0.33v2.73h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C43.79,28.2,43.83,28.02,43.91,27.86
			z M45.38,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C45.47,28.26,45.44,28.13,45.38,28.01z"/>
					<path className="st1" d="M47.34,27.01c-0.04-0.04-0.07-0.1-0.07-0.16c0-0.06,0.02-0.12,0.07-0.16c0.04-0.04,0.1-0.07,0.16-0.07
			c0.06,0,0.11,0.02,0.15,0.07c0.04,0.04,0.06,0.1,0.06,0.16c0,0.06-0.02,0.12-0.06,0.16c-0.04,0.04-0.09,0.07-0.15,0.07
			C47.44,27.08,47.39,27.05,47.34,27.01z M47.66,27.4v2.02h-0.33V27.4H47.66z"/>
					<path className="st1" d="M49.78,27.59c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33V27.4h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C49.43,27.37,49.63,27.44,49.78,27.59z"/>
					<path className="st1" d="M50.95,27.68v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26V27.4h0.26v-0.51h0.33v0.51h0.52v0.28H50.95z"/>
					<path className="st1" d="M53.13,27.49c0.13,0.08,0.23,0.17,0.29,0.29V27.4h0.33v2.07c0,0.18-0.04,0.35-0.12,0.49
			c-0.08,0.14-0.19,0.26-0.33,0.34c-0.14,0.08-0.31,0.12-0.5,0.12c-0.26,0-0.48-0.06-0.65-0.19c-0.17-0.13-0.28-0.3-0.31-0.51h0.33
			c0.04,0.12,0.11,0.22,0.22,0.3c0.11,0.07,0.25,0.11,0.41,0.11c0.18,0,0.33-0.06,0.44-0.17c0.11-0.12,0.17-0.28,0.17-0.49v-0.42
			c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37
			c-0.08-0.16-0.12-0.34-0.12-0.55c0-0.21,0.04-0.39,0.12-0.54c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13
			C52.85,27.37,53,27.41,53.13,27.49z M53.33,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09
			c-0.12,0-0.24,0.03-0.34,0.09c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4
			c0.06,0.11,0.14,0.2,0.24,0.26c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26
			c0.06-0.11,0.09-0.25,0.09-0.4C53.42,28.26,53.39,28.13,53.33,28.01z"/>
					<path className="st1" d="M54.89,27.46c0.11-0.06,0.24-0.1,0.39-0.1v0.35H55.2c-0.37,0-0.55,0.2-0.55,0.61v1.1h-0.33V27.4h0.33v0.33
			C54.7,27.62,54.78,27.53,54.89,27.46z"/>
					<path className="st1" d="M57.47,28.54h-1.59c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C57.48,28.4,57.47,28.47,57.47,28.54z M57.05,27.94c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C57.14,28.14,57.11,28.03,57.05,27.94z"/>
					<path className="st1" d="M57.91,27.86c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13c0.17,0,0.32,0.04,0.45,0.11
			c0.13,0.08,0.22,0.17,0.29,0.29V27.4h0.33v2.02h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.29c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C57.79,28.2,57.83,28.02,57.91,27.86
			z M59.38,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C59.47,28.26,59.44,28.13,59.38,28.01z"/>
					<path className="st1" d="M60.77,27.68v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26V27.4h0.26v-0.51h0.33v0.51h0.52v0.28H60.77z"/>
					<path className="st1" d="M63.48,28.54H61.9c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C63.49,28.4,63.49,28.47,63.48,28.54z M63.07,27.94c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C63.15,28.14,63.13,28.03,63.07,27.94z"/>
					<path className="st1" d="M63.93,27.86c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.31-0.13,0.48-0.13c0.15,0,0.3,0.04,0.43,0.11
			c0.13,0.07,0.23,0.17,0.3,0.29V26.7h0.33v2.73h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C63.81,28.2,63.85,28.02,63.93,27.86
			z M65.4,28.01c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C65.49,28.26,65.46,28.13,65.4,28.01z"/>
					<path className="st1" d="M67.64,29.38c-0.12-0.05-0.22-0.13-0.29-0.22c-0.07-0.09-0.11-0.2-0.12-0.33h0.34
			c0.01,0.1,0.06,0.18,0.14,0.25c0.08,0.06,0.19,0.1,0.33,0.1c0.13,0,0.22-0.03,0.3-0.08c0.07-0.06,0.11-0.13,0.11-0.21
			c0-0.09-0.04-0.15-0.12-0.2c-0.08-0.04-0.2-0.09-0.36-0.13c-0.15-0.04-0.27-0.08-0.36-0.12c-0.09-0.04-0.17-0.1-0.24-0.18
			c-0.07-0.08-0.1-0.18-0.1-0.32c0-0.1,0.03-0.2,0.09-0.28c0.06-0.09,0.15-0.15,0.26-0.2c0.11-0.05,0.24-0.08,0.38-0.08
			c0.22,0,0.4,0.06,0.53,0.17c0.14,0.11,0.21,0.27,0.22,0.46h-0.33c-0.01-0.11-0.05-0.19-0.13-0.25c-0.08-0.06-0.18-0.1-0.31-0.1
			c-0.12,0-0.21,0.03-0.28,0.08c-0.07,0.05-0.11,0.12-0.11,0.2c0,0.07,0.02,0.12,0.06,0.16c0.04,0.04,0.1,0.08,0.16,0.1
			c0.06,0.03,0.15,0.05,0.27,0.09c0.14,0.04,0.26,0.08,0.35,0.12c0.09,0.04,0.17,0.1,0.23,0.17c0.06,0.08,0.1,0.18,0.1,0.3
			c0,0.11-0.03,0.21-0.09,0.3c-0.06,0.09-0.15,0.16-0.26,0.21c-0.11,0.05-0.24,0.08-0.38,0.08C67.9,29.46,67.76,29.43,67.64,29.38z"
					/>
					<path className="st1" d="M69.6,29.33c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C69.92,29.46,69.75,29.42,69.6,29.33z M70.44,29.08c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			c0-0.16-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08S70.34,29.14,70.44,29.08z"/>
					<path className="st1" d="M71.89,26.7v2.73h-0.33V26.7H71.89z" />
					<path className="st1" d="M74.21,27.4v2.02h-0.33v-0.3c-0.06,0.1-0.15,0.18-0.26,0.24c-0.11,0.06-0.24,0.09-0.37,0.09
			c-0.15,0-0.29-0.03-0.42-0.1c-0.12-0.07-0.22-0.16-0.29-0.29c-0.07-0.13-0.11-0.29-0.11-0.48V27.4h0.33v1.14
			c0,0.2,0.05,0.35,0.15,0.46s0.23,0.16,0.41,0.16c0.18,0,0.32-0.06,0.42-0.17c0.1-0.11,0.15-0.27,0.15-0.48V27.4H74.21z"/>
					<path className="st1" d="M75.17,27.68v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26V27.4h0.26v-0.51h0.33v0.51h0.52v0.28H75.17z"/>
					<path className="st1" d="M76.1,27.01c-0.04-0.04-0.07-0.1-0.07-0.16c0-0.06,0.02-0.12,0.07-0.16c0.04-0.04,0.1-0.07,0.16-0.07
			c0.06,0,0.11,0.02,0.15,0.07c0.04,0.04,0.06,0.1,0.06,0.16c0,0.06-0.02,0.12-0.06,0.16c-0.04,0.04-0.09,0.07-0.15,0.07
			C76.2,27.08,76.15,27.05,76.1,27.01z M76.42,27.4v2.02h-0.33V27.4H76.42z"/>
					<path className="st1" d="M77.34,29.33c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13s0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55s-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C77.66,29.46,77.49,29.42,77.34,29.33z M78.18,29.08c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			c0-0.16-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08S78.08,29.14,78.18,29.08z"/>
					<path className="st1" d="M80.86,27.59c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12H79.3V27.4h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C80.51,27.37,80.71,27.44,80.86,27.59z"/>
					<path className="st1" d="M81.92,29.38c-0.12-0.05-0.22-0.13-0.29-0.22c-0.07-0.09-0.11-0.2-0.12-0.33h0.34
			c0.01,0.1,0.06,0.18,0.14,0.25c0.08,0.06,0.19,0.1,0.33,0.1c0.13,0,0.22-0.03,0.3-0.08c0.07-0.06,0.11-0.13,0.11-0.21
			c0-0.09-0.04-0.15-0.12-0.2c-0.08-0.04-0.2-0.09-0.36-0.13c-0.15-0.04-0.27-0.08-0.36-0.12c-0.09-0.04-0.17-0.1-0.24-0.18
			c-0.07-0.08-0.1-0.18-0.1-0.32c0-0.1,0.03-0.2,0.09-0.28c0.06-0.09,0.15-0.15,0.26-0.2c0.11-0.05,0.24-0.08,0.38-0.08
			c0.22,0,0.4,0.06,0.53,0.17c0.14,0.11,0.21,0.27,0.22,0.46H82.7c-0.01-0.11-0.05-0.19-0.13-0.25c-0.08-0.06-0.18-0.1-0.31-0.1
			c-0.12,0-0.21,0.03-0.28,0.08c-0.07,0.05-0.11,0.12-0.11,0.2c0,0.07,0.02,0.12,0.06,0.16c0.04,0.04,0.1,0.08,0.16,0.1
			c0.06,0.03,0.15,0.05,0.27,0.09c0.14,0.04,0.26,0.08,0.35,0.12c0.09,0.04,0.17,0.1,0.23,0.17c0.06,0.08,0.1,0.18,0.1,0.3
			c0,0.11-0.03,0.21-0.09,0.3c-0.06,0.09-0.15,0.16-0.26,0.21c-0.11,0.05-0.24,0.08-0.38,0.08C82.17,29.46,82.04,29.43,81.92,29.38z
			"/>
				</g>
			</g>
		</svg>


	)
}

export default HeaderLogo