import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root{
    --pink: #E31F71;
    --black: #212121;
    --blue: #016fb9;
    /* New color set */
    --orange: #FD651B;
    --dark_orange: #E35A18;
    --dark_blue: #0D315F;
    --dark_gray: #4A4A4A;
    scroll-padding-top: 10rem;

    &.light{
     

      body{
        transition: 0.5s;
        background-color: #f5f5f5;
        color: var(--dark_gray);
      }

      .logo{
        color: var(--dark_blue);
      }

      header.header-fixed{
        transition: 0.5s;
        background-color: #f5f5f550;
        a{
          transition: 0.5s;
          color: var(--black);
        }
        .menu,.menu:before, .menu:after{
          background-color: var(--black); 
        }
        .menu.active{
          background-color: rgba(555,555,555,0);
        }
      }

      footer.footer{
        transition: 0.5s;
        background-color: rgba(0,0,0,0.1);
        color: var(--black);
      }

      form{
        input,textarea, select{
          transition: 0.5s;
          border: solid 1px var(--black);
          color: var(--black);
          &::placeholder{
            transition: 0.5s;
            color: var(--black);
          }
        }
      }
    }
  }

  ul, li {
    text-decoration: none;
    list-style: none;
    margin: 0;
    padding:0;
  }

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html{
    font-size: 62.5%;
  }

  body{
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    background-color: var(--black);
    color: #FFFF;
  }

  body, input, textarea, select, button{
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 400;
  }

  a{
    text-decoration: none;
  }

  button, .button{
    border: none;
    cursor: pointer;
    background-color: var(--orange);
    color: #FFFF;
    border-radius: 2rem;
    font-weight: 500;
    transition: filter 0.25s;
    &:hover{
      filter: brightness(0.9);
    }
  }

  button:disabled, .button:disabled{
    filter: brightness(0.9);
    cursor: not-allowed;
  }


  .logo{
    font-size: 3rem;
    color: #FFFF;
    &::first-letter{
      color: var(--orange);
    }
  }

  .logo .last-letter{
    color: var(--orange);
  }

  // .col-md-4{
  // display: flex;
  // justify-content: center;
  // }


  @media only screen and (max-width: 480px) {
    // .col-md-4{
    // display: block;
  // }
    body{
      font-size: 1.3rem;
    }

    .logo{
      font-size: 1.8rem;
    }
  }


  @media only screen and (max-width: 960px) {
    &.light{
       nav{
          background-color: rgba(252, 243, 240, 0.95);;
        }
    }
  }
`;
