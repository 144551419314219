import styled from "styled-components";

export const Container = styled.section`
    margin-top: 15rem;

    /* .get-ready{
        text-align: center;
        margin-bottom: 2rem;
        color: var(--orange);
        font-size: 3rem;
        font-weight: bold;
    } */

    .booking-title{
        text-align: center;
        font-size: 4rem;
        margin-bottom: 2rem;
    }

    .calendar-container {
        display: flex;
        align-items: center;
    }

    .booking{
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 4rem;
        background-color: rgba(13, 49, 95, 0.8);
        padding: 3rem;
        border-radius: 1.2rem;
    }

    .booking .form-container{
        flex: 2;
    }

    .divider{
        border-right: 1px solid gray;
        height: 100%;
        padding: 3rem;
    }
    
    .time-block{
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        padding: 3rem 0 0 0;
    }

    .time{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border: none;
        height: 40px;
        width: auto;
        padding: 1rem 1.5rem;
        border-radius: 1.2rem;
        background-color: white;
        color: var(--dark_blue);
        cursor: pointer;
        transition: background-color 0.25s;
        font-size: 1.5rem;
        :hover{
            background-color: var(--orange);
            color: #FFF;
        }
        span{
            font-size: 1rem;
        }
    }

    .time-list .time.selected {
        background-color: var(--orange); 
        color: #FFF;
        font-weight: bold;
    } 


    .form-field{
        display: flex;
        flex-direction: column;
        width: 100%;
        label{
            font-size: 1.2rem;
            margin-bottom: .2rem;
            font-weight: 300;
            padding-top: .8rem;
            padding-left: 1rem;
            color: white;
        }
    }

    .form-input {
        width: 100%;
        max-width: 100%;
        padding: 0.8rem 2rem;
        border-radius: 1.2rem;
        outline: none;
        border: none;
        background-color: white;
        color: var(--dark_blue);
        font-size: 1.6rem;
    }

    select option{
        width:50px;   
    }


    .delete-icon {
        position: absolute;
        top: 2rem;
        right: 1rem;
        transform: translateY(-50%);
        cursor: pointer;
        color: red;
        font-size: 2rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .button{
        padding: 1rem 4rem;
        text-transform: uppercase;
        margin-top: 1.5rem;
        font-size: 1.2rem;
    }

    .plus-button{
        padding: .5rem 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-radius: 2rem;
        font-size: 1.2rem;
    }

    .guest-list-item {
        position: relative;
        padding-bottom: 1rem;
    }

    .inactive{
        opacity: 0.5;
        cursor: not-allowed;

    }

    .form-error{
        color: red;
        font-size: 1.2rem;
        font-weight: bold;
    }


    @media (max-width: 960px){
        .booking{
            flex-direction: column;
            justify-content: center; 
            gap: 2rem; 
        }

        .calendar-container {
            text-align: center;
            display: inline-block;
        }

        .divider{
            display:none;
        }

        .time-block{
            /* flex-direction: row;
            margin: 2rem 0;
            gap: 1rem;
            height: auto; */
            grid-template-columns: repeat(5, 1fr);
        }

        .booking .form-container{
            padding: 0 0 0 0;
        }
    }

    @media only screen and (max-width: 800px){
        .time-block{
            grid-template-columns: repeat(3, 1fr);
        }
    }


    @media only screen and (max-width: 480px){
        .booking{
            flex-direction: column;
            justify-content: center; 
            gap: 2rem;
            padding: 2rem;
        }
        
        .booking-title{
            font-size: 2rem;
        }

        .react-datepicker {
            width: 252px;
            font-size: 1rem;
        }

        .react-datepicker__header {
            width: 252px;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header{
            font-size: 1.2rem;    
        }

        .react-datepicker__day,
        .react-datepicker__month-text,
        .react-datepicker__quarter-text,
        .react-datepicker__year-text{
            height: 30px;
        }

        .calendar-container {
            text-align: center;
            display: inline-block;
        }

        .divider{
            display:none;
        }

        .time-block{
            grid-template-columns: repeat(2, 1fr)
            /* flex-direction: row;
            justify-content: center;
            margin: 1rem 0;
            gap: 1rem;
            height: auto; */
        }

        .booking .form-container{
            padding: 0 0 0 0;
        }

        .time{
            height: auto;
            width: auto;
            font-size: 1.2rem;
            :hover{
                background-color: var(--orange);
                color: #FFF;
            }
        }

        .form-error{
            font-size: 1rem;
        }

        .form-input {
            font-size: 1.3rem;
        }

        .button{
            padding: 1rem 2.3rem;
            font-size: 1.1rem;
        }
    }
`;