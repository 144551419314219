import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
// import { NavHashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import { useStatisticsContext } from "../../context/statisticsContext";
import HeaderLogo from "../../assets/logo/HeaderLogo";
import { useTheme } from "../../context/themeContext";

export function Header() {
  const [active, setActive] = useState(false);
  const { isLightMode, setIsLightMode } = useTheme();

  const { setActionType } = useStatisticsContext();

  function toggleTheme() {
    const newTheme = !isLightMode;
    setIsLightMode(newTheme);

    // Save user preference to localStorage
    localStorage.setItem("theme", newTheme ? "light" : "dark");

    document.documentElement.classList.toggle("light", newTheme);
  }

  function closeMenu() {
    setActive(false);
  }

  const handleConsultation = () => {
    setActionType("free-consultation-header");
    setActive(false);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");

    if (savedTheme) {
      const isLight = savedTheme === "light";
      setIsLightMode(isLight);
      document.documentElement.classList.toggle("light", isLight);
    } else {
      // If no preference is saved, apply theme based on time
      const currentHour = new Date().getHours();
      const isLight = currentHour >= 6 && currentHour < 18;
      setIsLightMode(isLight);
      document.documentElement.classList.toggle("light", isLight);
    }
  }, [setIsLightMode]);

  return (
    <Container className="header-fixed">
      <HeaderLogo isLightMode={isLightMode} />
      <Router>
        <input
          onChange={toggleTheme}
          className="container_toggle"
          type="checkbox"
          id="switch"
          name="mode"
        />
        <label htmlFor="switch">Toggle</label>
        <nav className={active ? "active" : ""}>
          <a href="#home" onClick={closeMenu}>
            Home
          </a>
          <a href="#about" onClick={closeMenu}>
            About
          </a>
          <a href="#project" onClick={closeMenu}>
            Services
          </a>
          <a href="#blogs" onClick={closeMenu}>
            Blog
          </a>
          <a href="#contact" onClick={closeMenu}>
            Contact
          </a>
          <a href="/#booking" className="button" onClick={handleConsultation}>
            Free Consultation
          </a>
        </nav>
        <div
          aria-expanded={active ? "true" : "false"}
          aria-haspopup="true"
          aria-label={active ? "Fechar menu" : "Abrir menu"}
          className={active ? "menu active" : "menu"}
          onClick={() => {
            setActive(!active);
          }}
        ></div>
      </Router>
    </Container>
  );
}
