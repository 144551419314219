import styled from "styled-components";


export const Container = styled.section`
  margin-top: 10rem;

  header{
    text-align: center;
    h2{
      text-align: center;
      font-size: 4rem;
    }
    p{
      color: var(--orange);
      font-weight: 500;
    }
  }

  .contacts{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    place-items: center;
    margin-top: 1.5rem;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      max-width: 30rem;
      gap: 2rem;
      background-color: var(--orange);
      border-radius: 1.4rem;
      padding: 1.6rem 2.8rem;
      transition: background-color 0.25s;
      &:hover{
        background-color: var(--dark_blue);
        cursor: pointer;
      }
      a{
          color: #fff;
          font-weight: 500;
          font-size: 1.5rem;
          i{
            font-size: 2.5rem;
          }
        }
    }
  }


  @media(max-width: 960px){
    .contacts{
      flex-direction: column;
      div{
        width: 100%;
        
      }
    }
  }

  @media only screen and (max-width: 400px){
    header {
      h2 {
        font-size: 2rem;
      }
    }

    .contacts{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      place-items: center;
      margin-top: 1.5rem;
      div{
        width: 100%;
        max-width: 30rem;
        gap: 1rem;
        background-color: var(--orange);
        border-radius: 1.4rem;
        padding: 1rem 2rem;
        a{
          font-size: 1.4rem;
          color: #fff;
          i{
            font-size: 1.5rem;
          }
        }
        
      }
  }

  }
  
`
/* old one - 2/1/2023 - 
@media(max-width: 960px){
    .contacts{
      flex-direction: column;
      div{
        width: 100%;
        flex-direction: column;
      }
    }

*/
