import { Container } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";
import { services } from "../../data/ServicesData";
import Lottie from "react-lottie";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';


export function Services() {
  const isSmallScreen = window.innerWidth <= 768;

  const size = isSmallScreen ? 150 : 300;

  return (
    <Container id="project">
      <h2>Our Services</h2>
      <div className="projects">
        {isSmallScreen ?
          <Swiper
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={'auto'}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination, Autoplay]}
          >
            {services.map((service, index) => (
              <SwiperSlide key={index}>
                <div className="project">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: service.img,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={size}
                    width={size}
                  />
                  <div className="body">
                    <h3>{service.name}</h3>
                    <p>
                      {service.description}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          :
          <>
            {services.map((service, index) => (
              <ScrollAnimation animateIn="flipInX" key={index}>
                <div className="project">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: service.img,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={size}
                    width={size}
                  />
                  <div className="body">
                    <h3>{service.name}</h3>
                    <p>
                      {service.description}
                    </p>
                  </div>
                </div>
              </ScrollAnimation>
            ))}
          </>
        }

        {/* {services.map((service, index) => (
          <ScrollAnimation animateIn="flipInX" key={index}>
            <div className="project">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: service.img,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={size}
                width={size}
              />
              <div className="body">
                <h3>{service.name}</h3>
                <p>
                  {service.description}
                </p>
              </div>
            </div>
          </ScrollAnimation>
        ))} */}
      </div>
    </Container>
  );
}
