import styled from "styled-components";

export const Container = styled.section`
  margin-top: 15rem;

  h2 {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 3rem;
  }

  .projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 2rem;
    padding: 1rem;
    overflow: hidden;
    border-radius: 1.2rem;

    .project {
      padding: 2rem 1.8rem;
      background-color: #0d315f;
      border-radius: 1.2rem;
      transition: 0.25s;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #fff;
      &:hover {
        transform: translateY(-5px);
        cursor: pointer;
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--blue);
        margin-bottom: 3.6rem;
        .project-links {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
        a > img {
          width: 5rem;
        }
      }

      h3 {
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-weight: bold;
      }

      p {
        letter-spacing: 0.12rem;
        margin-bottom: 2rem;
        a {
          color: #ffff;
          border-bottom: 1px solid var(--orange);
          transition: color 0.25s;
          &:hover {
            color: var(--orange);
          }
        }
      }

      footer {
        margin-top: auto;
        .tech-list {
          display: flex;
          align-items: center;
          gap: 2rem;
          font-size: 1.4rem;
          opacity: 0.6;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .projects {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-width: none; 
      -ms-overflow-style: none;
      gap: 1rem;
      padding: 0rem;
      .project {
        padding: 2rem 1.8rem;
        width: 100%;
        &:hover {
          transform: translateY(0px);
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .projects {
      /* grid-template-columns: 1fr; */
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      scrollbar-width: none; 
      -ms-overflow-style: none; 
      gap: 1rem;
      padding: 0rem;
      .project {
        padding: 2rem 1.8rem;
        height: 100%;
        width: 100%;
        &:hover {
          transform: translateY(0px);
          cursor: pointer;
        }
      }
    }

    .projects::-webkit-scrollbar {
      display: none;
    }

      h2{
        font-size: 2rem;
      }
  }
`;
