import styled from "styled-components";

export const Container = styled.section`
  margin-top: 12%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;
  background: rgba(0, 0, 0, 0);

  .hero-text {
    & > p {
      font-size: 1.8rem;
    }

    .greeting {
      font-size: 1.8rem;
    }

    h1 {
      font-size: 7rem;
    }

    h3 {
      color: var(--orange);
      margin: 1rem 0;
    }

    p.small-resume {
      margin-bottom: 3rem;
    }
  }
  // New added
  .social-media {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 5rem;
    padding-left: 1rem;

    img,
    span {
      font-size: 3rem;
      width: 3.5rem;
    }
  }

  .button {
    margin-top: 5rem;
    padding: 1.4rem 6rem;
    text-align: center;
  }

  .hero-image {
    img {
      max-width: 500px;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 35%;
    .hero-text {
      h1 {
        font-size: 2rem;
      }
    }

    .hero-image {
      /* display: none; */
      img {
        max-width: 250px;
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 35%;
  }

  @media (max-width: 480px) {
    margin-top: 50%;
    display: flex;
    flex-direction: column-reverse;

    .hero-text {
      .greeting {
        font-size: 1.5rem;
      }
      h1 {
        font-size: 3.5rem;
      }
    }
  }
`;
