import { Container } from "./styles";
import { Hero } from "../Hero/Hero";
import { About } from "../About/About";
import { Contact } from "../Contact/Contact";
import { Services } from "../Services/Services";
import { Booking } from "../Booking/Booking";
import { Blog } from "../Blog/Blog";

export function Main() {
  return (
    <Container>
      <Hero></Hero>
      <About></About>
      <Services></Services>
      <Booking></Booking>
      <Blog></Blog>

      <Contact></Contact>
    </Container>
  );
}
