import { Container, CopyRightContainer } from "./styles";
import linkedin from "../../assets/linkedin.svg";
import whatsapp from "../../assets/whatsapp.svg";
import instagramIcon from "../../assets/instagram.svg";
import facebookIcon from "../../assets/facebook.svg";
import FooterLogo from "../../assets/logo/FooterLogo";
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Container className="footer">
        <div className="services-list">
          <h2>Our Services</h2>
          <ul className="services-list">
            <li>Web Applications</li>
            <li>Mobile Applications</li>
            <li>Marketing & Advertising</li>
            <li>Media Production</li>
            <li>Enterprise Systems</li>
            <li>Cloud Computing Solutions</li>
          </ul>
        </div>
        <div className="subscription">
          <div className="contacts">
            <h2>Our Contact</h2>
            <div>
              <a href="mailto:info@zain-co.com">
                <i className="ph ph-envelope-simple"></i>
              </a>
              <a href="mailto:info@zain-co.com">info@zain-co.com</a>
            </div>
            <div className="support">
              <a href="mailto:support@zain-co.com">
                <i className="ph ph-envelope-simple"></i>
              </a>
              <a href="mailto:support@zain-co.com">support@zain-co.com</a>
            </div>
            <div>
              <a href="tel:+919630576848">
                <i className="ph ph-phone"></i>
              </a>
              <a href="tel:+13479863996">+1 (347) 986-3996</a>
            </div>
          </div>
          <div className="newsletter-container">
            <label>Subscribe to the newsletter</label>
            <div className="newsletter">
              <input type="text" className="subscribe-input" placeholder="Your email address" />
              <i className="ph ph-telegram-logo tele-icon"></i>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <FooterLogo />
          <div className="social-media">
            <a
              href="https://www.linkedin.com/company/zane-co"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="Linkedin" />
            </a>
            <a href="https://www.facebook.com/ZaneCo/" target="_blank" rel="noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a
              href="https://api.whatsapp.com/send/?phone=+971566917686&text=Hello+Hozifa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatsapp} alt="Whatsapp" />
            </a>
            <a
              href="https://www.instagram.com/zane.hozifa"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" />
            </a>
          </div>
        </div>
      </Container>
      <CopyRightContainer>
        Copyrights &copy; {currentYear} <span>Zane Technology</span>
      </CopyRightContainer>
    </>

  );
}
