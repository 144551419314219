import { useForm, useFieldArray } from "react-hook-form";
import { saveBooking } from "../../apis/booking.api";
import { toast } from "react-toastify";

interface Props {
  formActive: boolean | null;
  setFormActive: (value: boolean) => void;
  selectedDate: string | null;
  selectedTime: string | null;
  getDates: any
}

const BookingForm = ({ formActive, setFormActive, selectedDate, selectedTime, getDates }: Props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "guests",
  });

  const submit = (data: any) => {
    // Handle form submission logic here
    // console.log({ ...data, date: selectedDate, time: selectedTime });
    saveBooking({ ...data, date: selectedDate, time: selectedTime })
      .then((res) => {
        if (res.status === 200) {
          getDates()
          reset()
          setFormActive(false);
          toast.success("Booking saved successfully!", {
            position: toast.POSITION.BOTTOM_CENTER,
            pauseOnFocusLoss: false,
            closeOnClick: true,
            hideProgressBar: false,
            toastId: "succeeded",
          });
        }
      }).catch((err) => {
        console.log("Error saving booking", err);
        toast.error("Please try again", {
          position: toast.POSITION.BOTTOM_CENTER,
          pauseOnFocusLoss: false,
          closeOnClick: true,
          hideProgressBar: false,
          toastId: "error",
        });
      })
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(submit)}
        className={`${formActive ? "" : "inactive"}`}
      >
        <div className="booking-form">
          <div className="form-field">
            <label>Select Topic</label>
            <select
              {...register("topic", { required: true })}
              className="form-input"
              disabled={!formActive}
            >
              <option value="Web Applications">Web Applications</option>
              <option value="Mobile Applications">Mobile Applications</option>
              <option value="Marketing & Advertising">Marketing & Advertising</option>
              <option value="Media Production">Media Production</option>
              <option value="Enterprise Systems">Enterprise Systems</option>
              <option value="Cloud Computing Solutions">
                Cloud Computing Solutions
              </option>
              <option value="other">other</option>
            </select>
            {errors.topic && <p className="form-error">topic is required.</p>}
          </div>

          <div className="form-field">
            <label>Full Name</label>
            <input
              {...register("name", { required: true })}
              className="form-input"
              disabled={!formActive}
            />
            {errors.name && <p className="form-error">fullName is required.</p>}
          </div>
          <div className="form-field">
            <label>Phone Number</label>
            <input
              {...register("phone", { required: true })}
              className="form-input"
              disabled={!formActive}
            />
            {errors.phone && <p className="form-error">phoneNumber is required.</p>}
          </div>

          <div className="form-field">
            <label>Email</label>
            <input
              {...register("email", { required: true })}
              className="form-input"
              disabled={!formActive}
            />
            {errors.email && <p className="form-error">email is required.</p>}
          </div>
        </div>
        <ul>
          <button
            type="button"
            className="plus-button"
            onClick={() => append("")}
            disabled={!formActive}
          >
            <i className="ph ph-plus"></i> Add guest
          </button>
          <div className="guest-list">
            {fields.slice(0, 4).map((item, index) => (
              <div key={item.id} className="guest-list-item">
                <div className="form-field">
                  <input
                    {...register(`guests.${index}`)}
                    placeholder="example@email.com"
                    className="form-input"
                  />
                  <span className="delete-icon" onClick={() => remove(index)}>
                    <i className="ph ph-trash-simple"></i>
                  </span>
                </div>
              </div>
            ))}
          </div>
        </ul>
        <div className="form-field note">
          <label>Note</label>
          <textarea
            {...register("note")}
            className="form-input"
            disabled={!formActive}
          />
        </div>

        <button type="submit" className="button" disabled={!formActive}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default BookingForm;
