import web from "../assets/animation/Animation-web-development.json";
import mobile from "../assets/animation/Animation-mobile-development.json";
import marketing from "../assets/animation/Animation-marketing.json";
import media from "../assets/animation/Animation-media.json";
import enterprise from "../assets/animation/Animation-enterprise-system.json";
import cloud from "../assets/animation/Animation-cloud.json";

export const services = [
  {
    img: web,
    name: "Web Applications",
    description:
      "Develop robust and scalable web applications that enhance your online presence and streamline your operations.",
  },
  {
    img: mobile,
    name: "Mobile Applications",
    description:
      "Create user-friendly and high-performance mobile applications to engage your customers on the go.",
  },
  {
    img: marketing,
    name: "Marketing & Advertising",
    description:
      "Design and implement effective marketing and advertising strategies to increase your brand visibility and attract more customers.",
  },
  {
    img: media,
    name: "Media Production",
    description:
      "Produce high-quality media content, including videos, graphics, and animations, to elevate your brand image.",
  },
  {
    img: enterprise,
    name: "Enterprise Systems",
    description:
      "Implement comprehensive enterprise systems to improve your business processes and boost productivity.",
  },
  {
    img: cloud,
    name: "Cloud Computing Solutions",
    description:
      "Leverage the power of cloud computing to enhance your business agility and scalability.",
  },
];
