import "./Actor.css";

export default function Actor({ team }: any) {
  return (
    <div className="actor">
      <img src={team.img} className="actor-img" alt="html" />
      <p className="h3">{team.name}</p>
      <div className="p">{team.role}</div>
      <div className="location">{team.location}</div>
    </div>
  );
}
