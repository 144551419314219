import ScrollAnimation from "react-animate-on-scroll";
import Actor from "./actor/Actor";
import { Row, Col, Container as Con } from "react-bootstrap";
import { teams } from "../../data/TeamsData";
import { Swiper, SwiperSlide } from "swiper/react";

import "./styles.css";
//Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper/modules";
import { isDesktop } from "react-device-detect";

export function About() {
  const firstTwoTeams = teams.slice(0, 3); // First two elements
  const remainingTeams = teams.slice(3);

  return (
    <Con style={{ marginTop: "100px" }} id="about">
      <Row>
        <Col xs={12} md={6}>
          <div className="about-text">
            <ScrollAnimation animateIn="fadeInLeft">
              <h2 className="about">About Us</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
              <div style={{ textAlign: "justify" }}>
                <h3>Our Mission:</h3>
                At Zane Tech Solutions, our mission is to empower businesses
                with innovative technology solutions that drive growth and
                success. We are committed to delivering excellence and value to
                our clients through our expertise and dedication.
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="fadeInLeft"
              delay={0.2 * 1000}
              style={{ marginTop: "2rem", marginBottom: "2rem" }}
            >
              <div style={{ textAlign: "justify" }}>
                <h3>Our Vision:</h3>
                To be the leading provider of technology solutions for small
                businesses and companies, recognized for our innovation,
                quality, and customer-centric approach.
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
              <div style={{ textAlign: "justify" }}>
                <h3>Our Values:</h3>
                <ol>
                  <li>
                    Integrity: We uphold the highest standards of integrity in
                    all our actions.
                  </li>
                  <li>
                    Innovation: We continuously seek new and creative ways to
                    solve problems.
                  </li>
                  <li>
                    Excellence: We strive for excellence in everything we do.
                  </li>
                  <li>
                    Collaboration: We believe in the power of teamwork and
                    collaboration.
                  </li>
                  <li>
                    Customer Satisfaction: We are dedicated to exceeding our
                    clients’ expectations.
                  </li>
                </ol>
              </div>
            </ScrollAnimation>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="about-image" style={{ marginTop: "40px" }}>
            <Con>
              <Row>
                {firstTwoTeams.map((team: any, index: number) => (
                  <Col xs={4} md={4} key={index}>
                    {" "}
                    {/* Adjusted to xs={6} for two columns */}
                    <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
                      <Actor team={team} />
                    </ScrollAnimation>
                  </Col>
                ))}
              </Row>

              {/* Render the remaining teams in a carousel */}
              <Row style={{ marginTop: "10px" }}>
                <Col xs={12} md={12}>
                  {remainingTeams.length > 0 && (
                    <Swiper
                      // style={{ backgroundColor: "red" }}
                      spaceBetween={30}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      grabCursor={true}
                      centeredSlides={true}
                      slidesPerView={isDesktop ? 3 : 3}
                      loop={true}
                      modules={[Pagination, Autoplay]}
                      className="mySwiper"
                    >
                      {remainingTeams.map((team: any, index: number) => (
                        <SwiperSlide key={index}>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            delay={0.2 * 1000}
                          >
                            <Actor team={team} />
                          </ScrollAnimation>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </Col>
              </Row>
            </Con>
          </div>

          {/* <div className="about-image">
        <Con>
          <Row>
            {teams.map((team: any, index: number) => (
              <Col xs={4} md={4} key={index}>
                <ScrollAnimation animateIn="fadeInUp" delay={0.2 * 1000}>
                  <Actor team={team} />
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Con>
      </div> */}
        </Col>
      </Row>
    </Con>
  );
}
