import styled from "styled-components";


export const Container = styled.footer`
  background-color: #2b2b2b;
  padding: 3rem 10rem;
  margin-top: 10rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;

  label{
    margin-bottom: 2px;
  }


  h2{
    color: var(--orange);
    margin-bottom: 1.5rem;
  }

  .services-list{
    padding: 1rem 0 0 2rem;
    li{
      margin-bottom: 1rem;
      font-weight: lighter;
      &:before{
        content: "-";
        margin-right: 0.6rem;
      }
    }
  }

  .subscription{
    display: flex;
    flex-direction: column;
    gap: 5rem;
  }

  .newsletter{
    position: relative;
    width: 350px;
  }

  .subscribe-input{
    margin: 0 1rem 1rem 0;
    padding: .9rem;
    width: 100%;
    border-radius: 1.2rem;
    border: none;
    outline: none;
  }

  .tele-icon{
    position: absolute;
    color: var(--orange);
    bottom: 0;
    top: 50%;
    right: 10px;
    font-size: 2rem;
    transform: translateY(-50%);
    font-weight: bold;
  }

  .logo{
    font-size: 2.8rem;
  }

  p{
    letter-spacing: 0.2rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    img{
      width: 2.6rem;
      animation: spinning 5s infinite linear;
    }
  }

  .footer-bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
    }

  .social-media{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin: 2rem 0 0 0;

    img,span{
      font-size: 3rem;
      width: 3rem;
    }
  }

    .contacts{
      display: flex;
      flex-direction: column;
      align-items: start;
      div{
        display: flex;
        vertical-align: top;
        gap: 1.2rem;
        transition: background-color 0.25s;
        a{
          color: var(--light);
          font-weight: 500;
          font-size: 1.5rem;
          i{
            font-size: 2rem;
          }
        }
      }
      .support{
        margin: 1rem 0 2rem 0;
      }
  }


  @keyframes spinning {
    0%{
      transform: rotate(0);
    }
    100%{
      transform: rotate(360deg);
    }
  }


  @media(max-width: 960px){
    display: block;

    .newsletter-container{
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .contacts{
      align-items: start;
      div{
        justify-content: start;
        padding: 0 0 0 1.2rem;
      }
    }

    .subscribe-input{
      margin: 0 1rem 3rem 0;
    }

    .social-media{
      justify-content: start;
    }
  }


  @media(max-width: 800px){
      display: block;

      .subscribe-input{
        margin: 0 1rem 1rem 0;
      }

      .contacts{
        align-items: start;
        div{
          justify-content: start;
          padding: 0 0 0 1.2rem;
        }
      }

      .social-media{
        justify-content: start;
      }

      .newsletter-container{
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      .services-list{
        padding: 1.2rem;
        li{
          margin-bottom: 1rem;
          font-weight: lighter;
          &:before{
            content: "-";
            margin-right: 0.6rem;
          }
        }
    }
  }
  
  @media(max-width: 600px){
    display: block;
    padding: 4rem 1rem;

    .services-list{
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .newsletter-container{
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    .contacts{
      align-items: start;
      div{
        justify-content: start;
        padding: 0 0 0 1.2rem;
        a{
          color: var(--light);
          font-weight: 300;
          font-size: 1.3rem;
          i{
            font-size: 1.7rem;
          }
        }
      }
    }

    .newsletter{
      width: 100%;
    }

    .tele-icon{
      font-size: 1.5rem;
    }

    .social-media{
      justify-content: start;
    }

    p{
      font-size: 1.2rem;
    }

    .logo{
      font-size: 2rem;
    }

    .footer-bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

export const CopyRightContainer = styled.div`
  margin-top: 0;
  font-size: 1.4rem;
  color: var(--white);
  text-align: center;
  padding: 1rem;

  span{
    color: var(--orange);
  }
`