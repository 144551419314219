import styled from "styled-components";

export const Container = styled.section`
  margin-top: 10rem;

  header {
    text-align: center;

    h2 {
      text-align: center;
      font-size: 4rem;
    }

    p {
      color: var(--orange);
      font-weight: 500;
    }

  }

  .blog {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    place-items: center;
    margin-top: 1.5rem;
    iframe{
      border-radius: 1.2rem;
    }
  }

  @media (max-width: 960px) {
    .blog {
      flex-direction: column;
      width: 100%;
    }
  }

  @media only screen and (max-width: 400px) {
    .blog {
      flex-direction: column;
      width: 100%;
    }
    
    header {
      h2 {
        font-size: 2rem;
      }
    }
  }
`;
