import { Container } from "./styles";

export function Blog() {
  const isSmallScreen = window.innerWidth <= 600;

  const width = isSmallScreen ? "100% " : 550;

  return (
    <Container id="blogs">
      <header>
        <h2>Blogs</h2>
      </header>
      <div className="blog">
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:share:7154153695780212736"
          height={isSmallScreen ? "400" : "499"}
          width={width}
          // frameborder="0"
          // allowfullscreen=""
          title="Embedded post"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7165656490823897088"
          height={isSmallScreen ? "400" : "581"}
          width={width}
          // frameborder="0"
          // allowfullscreen=""
          title="Embedded post"
        ></iframe>
        <iframe
          src="https://www.linkedin.com/embed/feed/update/urn:li:share:7145988427312693248"
          height={isSmallScreen ? "400" : "494"}
          width={width}
          // frameborder="0"
          // allowfullscreen=""
          title="Embedded post"
        ></iframe>
      </div>
    </Container>
  );
}
