import { useState, useMemo, useEffect } from "react";
import { Container } from "./styles";
import DatePicker from "react-datepicker";
import "../../styles/datepicker-custom.css";
import { format, parseISO, startOfDay, startOfToday } from "date-fns";
import BookingForm from "./BookingForm";
import { useStatisticsContext } from "../../context/statisticsContext";
import { getAllDates } from "../../apis/calender.api";
import { DateInfo } from "../../types/booking";
import { isAfter } from "date-fns";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export function Booking() {
  const [dates, setDates] = useState<DateInfo[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [isDateSelected, setIsDateSelected] = useState<boolean>(false);
  const [formActive, setFormActive] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [timezone, setTimezone] = useState("");

  const { setActionType } = useStatisticsContext();


  useEffect(() => {
    const date = new Date();
    const offsetInMinutes = date.getTimezoneOffset();
    const offsetInHours = Math.abs(offsetInMinutes) / 60;
    const sign = offsetInMinutes <= 0 ? "+" : "-";
    const gmtString = `GMT${sign}${offsetInHours}`;

    setTimezone(gmtString);
  }, []);

  const getDates = async () => {
    await getAllDates()
      .then((res) => {
        if (res.data.data.length > 0) {
          const today = startOfToday()

          const filteredDates = res.data.data
            .filter((date: any) => {
              const parsedDate = startOfDay(parseISO(date.date));
              console.log(`Checking date: ${parsedDate}, Today: ${today}`);
              return isAfter(parsedDate, today);
            })
            .sort(
              (a: any, b: any) =>
                new Date(a.date).getTime() - new Date(b.date).getTime()
            );
          setDates(filteredDates);
          const initialDate = new Date(filteredDates[0].date);
          setStartDate(initialDate);
          setSelectedDate(filteredDates[0].date);
          setIsDateSelected(true); // Automatically show the time slots for the initial date
        }
      })
      .catch((err) => {
        console.error("Error fetching dates:", err);
      });
  };

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, []);

  const availableDates = useMemo(() => {
    return dates?.map((date) => new Date(date.date));
  }, [dates]);

  const handleDateSelect = (date: Date | null) => {
    if (date) {
      let formattedDate = format(date, "yyyy-MM-dd");
      formattedDate = formattedDate + "T00:00:00-04:00";
      console.log("Selected date:", formattedDate);
      setSelectedDate(formattedDate);
      setIsDateSelected(true); // Show the time slots
      setActionType("select-booking-date");
    }
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
    setFormActive(true); // Show the booking form
    setActionType("select-booking-time");
  };

  return (
    <Container id="booking">
      <h2 className="booking-title">Book Your Free Consultation</h2>
      {/* <p className="get-ready">Ready to get started on your project? </p> */}
      <div className="booking">
        <div className="calendar-container">
          <DatePicker
            selected={startDate}
            includeDates={availableDates}
            onChange={(date: Date | null) => setStartDate(date)}
            onSelect={(date: Date | null) => handleDateSelect(date)} // When day is clicked
            inline
          />
          <div className="divider"></div>
        </div>
        {isDateSelected && selectedDate && (
          <div className="time-list">
            {dates.map((date) => (
              <div key={date.date}>
                {selectedDate === date.date && (
                  <div className="time-block">
                    {date.times.map((time) => (
                      <div
                        className={`time ${selectedTime === time ? "selected" : ""
                          }`}
                        key={time}
                        onClick={() => handleTimeSelect(time)}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content={timezone}
                      >
                        <i className="ph ph-clock"></i>
                        {time}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        {/* display react tooltip */}
        <Tooltip id="my-tooltip" />
        <div className="form-container">
          <BookingForm
            formActive={formActive}
            setFormActive={setFormActive}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            getDates={getDates}
          />
        </div>
      </div>
    </Container>
  );
}
