import { Container } from "./styles";
import { Form } from "../Form/Form";

export function Contact() {
  return (
    <Container id="contact">
      <header>
        <h2>Contact Us</h2>
      </header>
      <div className="contacts">
        <div>
          <a href="mailto:info@zain-co.com">
            <i className="ph ph-envelope-simple"></i>
          </a>
          <a href="mailto:info@zain-co.com">info@zain-co.com</a>
        </div>
        <div>
          <a href="tel:+919630576848">
            <i className="ph ph-phone"></i>
          </a>
          <a href="tel:+13479863996">+1 (347) 986-3996</a>
        </div>
      </div>
      <Form></Form>
    </Container>
  );
}
