import { useTheme } from '../../context/themeContext'
import { isMobile } from 'react-device-detect';

const FooterLogo = () => {
	const { isLightMode } = useTheme()
	return (
		<div>
			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 100 100" xmlSpace="preserve" className='logo-style' height={150} width={isMobile ? 130 : 200}>
				<style type="text/css">
					{`.st0{fill:${isLightMode ? "#0D315F" : "#FCF3F0"};}
                    .footer-st1{fill:${isLightMode ? "#FCF3F0" : "#0D315F"};}
                    .st2{fill:#E35A18;}`}
				</style>

				<g>
					<g>
						<path className="st0" d="M66.64,22.14v19.27c0,3.85-3.15,7.01-7.01,7.01H40.37c-3.85,0-7.01-3.15-7.01-7.01V22.14
			c0-3.85,3.15-7.01,7.01-7.01h19.27C63.49,15.14,66.64,18.29,66.64,22.14z"/>
						<g>
							<path className="footer-st1" d="M47.47,38.32l-7.23,3.97l-1.09-0.61c-0.8-0.45-1.3-1.3-1.31-2.22c0-0.92,0.49-1.77,1.28-2.23l11.79-6.81
				l-11.79-6.8c-1.22-0.71-1.64-2.27-0.93-3.5c0.7-1.22,2.27-1.64,3.49-0.93l14.71,8.5l0.92,0.53c0.79,0.46,1.28,1.3,1.28,2.21
				c0,0.92-0.49,1.76-1.28,2.22L47.47,38.32z"/>
							<path className="footer-st1" d="M58.57,48.42h-7.43l-9.01-5.06l5.28-2.9L57.28,46C58.17,46.5,58.65,47.46,58.57,48.42z" />
							<path className="st2" d="M61.81,26.05c-0.47,0.82-1.33,1.28-2.21,1.28c-0.44,0-0.88-0.11-1.28-0.34L42.7,17.96
				c-1.01-0.58-1.47-1.75-1.2-2.83h6.54l12.84,7.41C62.1,23.26,62.52,24.82,61.81,26.05z"/>
						</g>
					</g>
					<g>
						<path className="st0" d="M11.98,79.12H0.07L9.75,55h11.9L11.98,79.12z M3.45,66.43L1,66.62V55h6.9L3.45,66.43z M21.19,79.12h-7.27
			l3.61-9.45l3.66-0.14V79.12z"/>
						<path className="st0" d="M29.71,63.38l4.95,15.28c-0.79,0.42-2.36,0.69-3.98,0.69c-4.68,0-7.27-2.92-7.27-8.06
			C23.41,67.17,26.01,64.07,29.71,63.38z M40.78,55l7.59,24.13H36.84L29.06,55H40.78z"/>
						<path className="st0" d="M60.74,55v24.13H50.18V55H60.74z M64.81,55c4.77,0,8.75,2.55,8.75,9.68v14.45H62.64V55H64.81z" />
						<path className="st0" d="M87,70.37h12.04c0.37,5.46-3.7,9.35-10.6,9.35c-8.57,0-12.13-3.61-12.13-12.41c0-9.49,4.45-12.87,10.7-12.87
			V70.37z"/>
						<path className="st2" d="M88.85,54.44c7.36,0,11.62,4.4,11.02,14.26H88.85V54.44z" />
					</g>
					<g>
						<path className="st2" d="M18.66,81.29v0.27h-0.69v2.3h-0.33v-2.3h-0.69v-0.27H18.66z" />
						<path className="st2" d="M20.87,82.98h-1.59c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C20.88,82.84,20.88,82.91,20.87,82.98z M20.45,82.38c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C20.54,82.58,20.51,82.47,20.45,82.38z"/>
						<path className="st2" d="M21.31,82.3c0.08-0.16,0.2-0.28,0.34-0.36c0.15-0.09,0.31-0.13,0.5-0.13c0.24,0,0.45,0.06,0.6,0.18
			c0.16,0.12,0.26,0.29,0.31,0.5h-0.36c-0.03-0.12-0.1-0.22-0.2-0.29c-0.1-0.07-0.22-0.11-0.36-0.11c-0.19,0-0.34,0.07-0.46,0.2
			c-0.12,0.13-0.17,0.32-0.17,0.56c0,0.24,0.06,0.43,0.17,0.56c0.12,0.13,0.27,0.2,0.46,0.2c0.14,0,0.27-0.03,0.36-0.1
			c0.1-0.07,0.16-0.17,0.2-0.3h0.36c-0.05,0.21-0.16,0.37-0.32,0.5c-0.16,0.12-0.36,0.19-0.6,0.19c-0.19,0-0.36-0.04-0.5-0.13
			c-0.15-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C21.19,82.64,21.23,82.46,21.31,82.3z"/>
						<path className="st2" d="M24.91,81.9c0.12,0.07,0.22,0.16,0.28,0.29c0.07,0.13,0.1,0.29,0.1,0.48v1.19h-0.33v-1.14
			c0-0.2-0.05-0.36-0.15-0.46c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33
			v-2.73h0.33v1c0.07-0.1,0.16-0.18,0.27-0.24s0.24-0.08,0.39-0.08C24.65,81.81,24.79,81.84,24.91,81.9z"/>
						<path className="st2" d="M27.39,82.03c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33v-2.02h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C27.04,81.81,27.24,81.88,27.39,82.03z"/>
						<path className="st2" d="M28.51,83.77c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13s0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C28.83,83.9,28.67,83.86,28.51,83.77z M29.35,83.52c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			s-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08C29.14,83.6,29.25,83.58,29.35,83.52z"/>
						<path className="st2" d="M30.8,81.14v2.73h-0.33v-2.73H30.8z" />
						<path className="st2" d="M31.73,83.77c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C32.05,83.9,31.88,83.86,31.73,83.77z M32.57,83.52c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			s-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08C32.35,83.6,32.46,83.58,32.57,83.52z"/>
						<path className="st2" d="M34.96,81.93c0.13,0.08,0.23,0.17,0.29,0.29v-0.37h0.33v2.07c0,0.18-0.04,0.35-0.12,0.49
			c-0.08,0.14-0.19,0.26-0.33,0.34c-0.14,0.08-0.31,0.12-0.5,0.12c-0.26,0-0.48-0.06-0.65-0.19c-0.17-0.13-0.28-0.3-0.31-0.51h0.33
			c0.04,0.12,0.11,0.22,0.22,0.3c0.11,0.07,0.25,0.11,0.41,0.11c0.18,0,0.33-0.06,0.44-0.17c0.11-0.12,0.17-0.28,0.17-0.49v-0.42
			c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37
			c-0.08-0.16-0.12-0.34-0.12-0.55c0-0.21,0.04-0.39,0.12-0.54c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13
			C34.68,81.81,34.83,81.85,34.96,81.93z M35.15,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09
			c-0.12,0-0.24,0.03-0.34,0.09c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4
			c0.06,0.11,0.14,0.2,0.24,0.26c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26
			c0.06-0.11,0.09-0.25,0.09-0.4C35.24,82.7,35.21,82.57,35.15,82.45z"/>
						<path className="st2" d="M37.85,81.84l-1.2,2.97h-0.34l0.39-0.97l-0.8-2h0.37l0.62,1.64l0.62-1.64H37.85z" />
						<path className="st2" d="M39.14,82.3c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13c0.17,0,0.32,0.04,0.45,0.11
			c0.13,0.08,0.22,0.17,0.29,0.29v-0.37h0.33v2.02H40.7v-0.38c-0.07,0.12-0.16,0.22-0.29,0.29c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C39.02,82.64,39.06,82.46,39.14,82.3
			z M40.61,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C40.7,82.7,40.67,82.57,40.61,82.45z"/>
						<path className="st2" d="M43.15,82.03c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33v-2.02h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C42.8,81.81,43,81.88,43.15,82.03z"/>
						<path className="st2" d="M43.91,82.3c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.31-0.13,0.48-0.13c0.15,0,0.3,0.04,0.43,0.11
			c0.13,0.07,0.23,0.17,0.3,0.29v-1.07h0.33v2.73h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C43.79,82.64,43.83,82.46,43.91,82.3
			z M45.38,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C45.47,82.7,45.44,82.57,45.38,82.45z"/>
						<path className="st2" d="M47.34,81.45c-0.04-0.04-0.07-0.1-0.07-0.16c0-0.06,0.02-0.12,0.07-0.16c0.04-0.04,0.1-0.07,0.16-0.07
			c0.06,0,0.11,0.02,0.15,0.07c0.04,0.04,0.06,0.1,0.06,0.16c0,0.06-0.02,0.12-0.06,0.16c-0.04,0.04-0.09,0.07-0.15,0.07
			C47.44,81.52,47.39,81.49,47.34,81.45z M47.66,81.84v2.02h-0.33v-2.02H47.66z"/>
						<path className="st2" d="M49.78,82.03c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12h-0.33v-2.02h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C49.43,81.81,49.63,81.88,49.78,82.03z"/>
						<path className="st2" d="M50.95,82.12v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26v-0.28h0.26v-0.51h0.33v0.51h0.52v0.28H50.95z"/>
						<path className="st2" d="M53.13,81.93c0.13,0.08,0.23,0.17,0.29,0.29v-0.37h0.33v2.07c0,0.18-0.04,0.35-0.12,0.49
			c-0.08,0.14-0.19,0.26-0.33,0.34c-0.14,0.08-0.31,0.12-0.5,0.12c-0.26,0-0.48-0.06-0.65-0.19c-0.17-0.13-0.28-0.3-0.31-0.51h0.33
			c0.04,0.12,0.11,0.22,0.22,0.3c0.11,0.07,0.25,0.11,0.41,0.11c0.18,0,0.33-0.06,0.44-0.17c0.11-0.12,0.17-0.28,0.17-0.49v-0.42
			c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37
			c-0.08-0.16-0.12-0.34-0.12-0.55c0-0.21,0.04-0.39,0.12-0.54c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13
			C52.85,81.81,53,81.85,53.13,81.93z M53.33,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09
			c-0.12,0-0.24,0.03-0.34,0.09c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4
			c0.06,0.11,0.14,0.2,0.24,0.26c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26
			c0.06-0.11,0.09-0.25,0.09-0.4C53.42,82.7,53.39,82.57,53.33,82.45z"/>
						<path className="st2" d="M54.89,81.9c0.11-0.06,0.24-0.1,0.39-0.1v0.35H55.2c-0.37,0-0.55,0.2-0.55,0.61v1.1h-0.33v-2.02h0.33v0.33
			C54.7,82.06,54.78,81.97,54.89,81.9z"/>
						<path className="st2" d="M57.47,82.98h-1.59c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C57.48,82.84,57.47,82.91,57.47,82.98z M57.05,82.38c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C57.14,82.58,57.11,82.47,57.05,82.38z"/>
						<path className="st2" d="M57.91,82.3c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.3-0.13,0.48-0.13c0.17,0,0.32,0.04,0.45,0.11
			c0.13,0.08,0.22,0.17,0.29,0.29v-0.37h0.33v2.02h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.29c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C57.79,82.64,57.83,82.46,57.91,82.3
			z M59.38,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C59.47,82.7,59.44,82.57,59.38,82.45z"/>
						<path className="st2" d="M60.77,82.12v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26v-0.28h0.26v-0.51h0.33v0.51h0.52v0.28H60.77z"/>
						<path className="st2" d="M63.48,82.98H61.9c0.01,0.2,0.08,0.35,0.2,0.47c0.12,0.11,0.27,0.17,0.44,0.17c0.14,0,0.26-0.03,0.36-0.1
			c0.1-0.07,0.16-0.16,0.2-0.27h0.36c-0.05,0.19-0.16,0.35-0.32,0.47c-0.16,0.12-0.36,0.18-0.59,0.18c-0.19,0-0.36-0.04-0.51-0.13
			c-0.15-0.09-0.27-0.21-0.35-0.37c-0.08-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.12-0.55c0.08-0.16,0.2-0.28,0.35-0.36
			c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.5,0.13c0.14,0.08,0.26,0.2,0.34,0.34c0.08,0.15,0.12,0.31,0.12,0.5
			C63.49,82.84,63.49,82.91,63.48,82.98z M63.07,82.38c-0.06-0.09-0.13-0.16-0.23-0.21c-0.1-0.05-0.2-0.07-0.32-0.07
			c-0.17,0-0.31,0.05-0.43,0.16c-0.12,0.11-0.18,0.26-0.2,0.45h1.25C63.15,82.58,63.13,82.47,63.07,82.38z"/>
						<path className="st2" d="M63.93,82.3c0.08-0.16,0.2-0.28,0.34-0.36c0.14-0.09,0.31-0.13,0.48-0.13c0.15,0,0.3,0.04,0.43,0.11
			c0.13,0.07,0.23,0.17,0.3,0.29v-1.07h0.33v2.73h-0.33v-0.38c-0.07,0.12-0.16,0.22-0.29,0.3c-0.13,0.08-0.28,0.12-0.45,0.12
			c-0.18,0-0.34-0.04-0.48-0.13c-0.14-0.09-0.26-0.21-0.34-0.37c-0.08-0.16-0.12-0.34-0.12-0.55C63.81,82.64,63.85,82.46,63.93,82.3
			z M65.4,82.45c-0.06-0.11-0.14-0.2-0.24-0.26c-0.1-0.06-0.22-0.09-0.34-0.09c-0.12,0-0.24,0.03-0.34,0.09
			c-0.1,0.06-0.18,0.15-0.24,0.26c-0.06,0.11-0.09,0.25-0.09,0.4c0,0.15,0.03,0.29,0.09,0.4c0.06,0.11,0.14,0.2,0.24,0.26
			c0.1,0.06,0.21,0.09,0.34,0.09c0.12,0,0.24-0.03,0.34-0.09c0.1-0.06,0.18-0.15,0.24-0.26c0.06-0.11,0.09-0.25,0.09-0.4
			C65.49,82.7,65.46,82.57,65.4,82.45z"/>
						<path className="st2" d="M67.64,83.82c-0.12-0.05-0.22-0.13-0.29-0.22c-0.07-0.09-0.11-0.2-0.12-0.33h0.34
			c0.01,0.1,0.06,0.18,0.14,0.25s0.19,0.1,0.33,0.1c0.13,0,0.22-0.03,0.3-0.08c0.07-0.06,0.11-0.13,0.11-0.21
			c0-0.09-0.04-0.15-0.12-0.2c-0.08-0.04-0.2-0.09-0.36-0.13c-0.15-0.04-0.27-0.08-0.36-0.12c-0.09-0.04-0.17-0.1-0.24-0.18
			c-0.07-0.08-0.1-0.18-0.1-0.32c0-0.1,0.03-0.2,0.09-0.28c0.06-0.09,0.15-0.15,0.26-0.2c0.11-0.05,0.24-0.08,0.38-0.08
			c0.22,0,0.4,0.06,0.53,0.17c0.14,0.11,0.21,0.27,0.22,0.46h-0.33c-0.01-0.11-0.05-0.19-0.13-0.25c-0.08-0.06-0.18-0.1-0.31-0.1
			c-0.12,0-0.21,0.03-0.28,0.08c-0.07,0.05-0.11,0.12-0.11,0.2c0,0.07,0.02,0.12,0.06,0.16c0.04,0.04,0.1,0.08,0.16,0.1
			c0.06,0.03,0.15,0.05,0.27,0.09c0.14,0.04,0.26,0.08,0.35,0.12c0.09,0.04,0.17,0.1,0.23,0.17c0.06,0.08,0.1,0.18,0.1,0.3
			c0,0.11-0.03,0.21-0.09,0.3c-0.06,0.09-0.15,0.16-0.26,0.21c-0.11,0.05-0.24,0.08-0.38,0.08C67.9,83.9,67.76,83.87,67.64,83.82z"
						/>
						<path className="st2" d="M69.6,83.77c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13c0.19,0,0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C69.92,83.9,69.75,83.86,69.6,83.77z M70.44,83.52c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41s-0.03-0.3-0.09-0.41
			c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08c-0.1,0.06-0.18,0.14-0.24,0.25
			c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25c0.1,0.06,0.21,0.08,0.33,0.08
			S70.34,83.58,70.44,83.52z"/>
						<path className="st2" d="M71.89,81.14v2.73h-0.33v-2.73H71.89z" />
						<path className="st2" d="M74.21,81.84v2.02h-0.33v-0.3c-0.06,0.1-0.15,0.18-0.26,0.24c-0.11,0.06-0.24,0.09-0.37,0.09
			c-0.15,0-0.29-0.03-0.42-0.1c-0.12-0.07-0.22-0.16-0.29-0.29s-0.11-0.29-0.11-0.48v-1.18h0.33v1.14c0,0.2,0.05,0.35,0.15,0.46
			c0.1,0.11,0.23,0.16,0.41,0.16c0.18,0,0.32-0.06,0.42-0.17c0.1-0.11,0.15-0.27,0.15-0.48v-1.11H74.21z"/>
						<path className="st2" d="M75.17,82.12v1.19c0,0.1,0.02,0.17,0.06,0.21c0.04,0.04,0.11,0.06,0.21,0.06h0.24v0.28h-0.3
			c-0.18,0-0.32-0.04-0.41-0.13c-0.09-0.09-0.14-0.23-0.14-0.42v-1.19h-0.26v-0.28h0.26v-0.51h0.33v0.51h0.52v0.28H75.17z"/>
						<path className="st2" d="M76.1,81.45c-0.04-0.04-0.07-0.1-0.07-0.16c0-0.06,0.02-0.12,0.07-0.16c0.04-0.04,0.1-0.07,0.16-0.07
			c0.06,0,0.11,0.02,0.15,0.07c0.04,0.04,0.06,0.1,0.06,0.16c0,0.06-0.02,0.12-0.06,0.16c-0.04,0.04-0.09,0.07-0.15,0.07
			C76.2,81.52,76.15,81.49,76.1,81.45z M76.42,81.84v2.02h-0.33v-2.02H76.42z"/>
						<path className="st2" d="M77.34,83.77c-0.15-0.09-0.27-0.21-0.36-0.37c-0.09-0.16-0.13-0.34-0.13-0.55c0-0.21,0.04-0.39,0.13-0.55
			c0.09-0.16,0.21-0.28,0.36-0.37c0.15-0.08,0.32-0.13,0.51-0.13s0.36,0.04,0.51,0.13c0.15,0.08,0.27,0.21,0.36,0.36
			c0.09,0.16,0.13,0.34,0.13,0.55c0,0.21-0.05,0.39-0.14,0.55c-0.09,0.16-0.21,0.28-0.37,0.37c-0.15,0.09-0.33,0.13-0.51,0.13
			C77.66,83.9,77.49,83.86,77.34,83.77z M78.18,83.52c0.1-0.06,0.19-0.14,0.25-0.25c0.06-0.11,0.1-0.25,0.1-0.41
			s-0.03-0.3-0.09-0.41c-0.06-0.11-0.14-0.2-0.25-0.25c-0.1-0.06-0.21-0.08-0.33-0.08c-0.12,0-0.23,0.03-0.33,0.08
			c-0.1,0.06-0.18,0.14-0.24,0.25c-0.06,0.11-0.09,0.25-0.09,0.41c0,0.16,0.03,0.3,0.09,0.42c0.06,0.11,0.14,0.2,0.24,0.25
			c0.1,0.06,0.21,0.08,0.33,0.08S78.08,83.58,78.18,83.52z"/>
						<path className="st2" d="M80.86,82.03c0.15,0.15,0.22,0.36,0.22,0.64v1.19h-0.33v-1.14c0-0.2-0.05-0.36-0.15-0.46
			c-0.1-0.11-0.23-0.16-0.41-0.16c-0.17,0-0.31,0.06-0.42,0.17c-0.1,0.11-0.15,0.27-0.15,0.48v1.12H79.3v-2.02h0.33v0.29
			c0.07-0.1,0.15-0.18,0.27-0.24s0.24-0.08,0.37-0.08C80.51,81.81,80.71,81.88,80.86,82.03z"/>
						<path className="st2" d="M81.92,83.82c-0.12-0.05-0.22-0.13-0.29-0.22c-0.07-0.09-0.11-0.2-0.12-0.33h0.34
			c0.01,0.1,0.06,0.18,0.14,0.25s0.19,0.1,0.33,0.1c0.13,0,0.22-0.03,0.3-0.08c0.07-0.06,0.11-0.13,0.11-0.21
			c0-0.09-0.04-0.15-0.12-0.2c-0.08-0.04-0.2-0.09-0.36-0.13c-0.15-0.04-0.27-0.08-0.36-0.12c-0.09-0.04-0.17-0.1-0.24-0.18
			c-0.07-0.08-0.1-0.18-0.1-0.32c0-0.1,0.03-0.2,0.09-0.28c0.06-0.09,0.15-0.15,0.26-0.2c0.11-0.05,0.24-0.08,0.38-0.08
			c0.22,0,0.4,0.06,0.53,0.17c0.14,0.11,0.21,0.27,0.22,0.46H82.7c-0.01-0.11-0.05-0.19-0.13-0.25c-0.08-0.06-0.18-0.1-0.31-0.1
			c-0.12,0-0.21,0.03-0.28,0.08c-0.07,0.05-0.11,0.12-0.11,0.2c0,0.07,0.02,0.12,0.06,0.16c0.04,0.04,0.1,0.08,0.16,0.1
			c0.06,0.03,0.15,0.05,0.27,0.09c0.14,0.04,0.26,0.08,0.35,0.12c0.09,0.04,0.17,0.1,0.23,0.17c0.06,0.08,0.1,0.18,0.1,0.3
			c0,0.11-0.03,0.21-0.09,0.3c-0.06,0.09-0.15,0.16-0.26,0.21c-0.11,0.05-0.24,0.08-0.38,0.08C82.17,83.9,82.04,83.87,81.92,83.82z"
						/>
					</g>
				</g>
			</svg>
		</div>
	)
}

export default FooterLogo